const WEATHER_DATA_FETCH_REQUESTED = "WEATHER_DATA_REQUESTED";
const WEATHER_DATA_FETCH_FAILED = "WEATHER_DATA_FETCH_FAILED";
const WEATHER_DATA_FETCH_SUCCEEDED = "WEATHER_DATA_FETCH_SUCCEEDED";

const SENSEBOXES_FETCH_REQUESTED = "SENSEBOXES_FETCH_REQUESTED";
const SENSEBOXES_FETCH_FAILED = "SENSEBOXES_FETCH_FAILED";
const SENSEBOXES_FETCH_SUCCEEDED = "SENSEBOXES_FETCH_SUCCEEDED";

const SENSEBOX_INFO_FETCH_REQUESTED = "SENSEBOX_INFO_FETCH_REQUESTED";
const SENSEBOX_INFO_FETCH_FAILED = "SENSEBOX_INFO_FETCH_FAILED";
const SENSEBOX_INFO_FETCH_SUCCEEDED = "SENSEBOX_INFO_FETCH_SUCCEEDED";

const SENSEBOX_DB_MISC_FETCH_REQUESTED = "SENSEBOX_DB_MISC_FETCH_REQUESTED";
const SENSEBOX_DB_MISC_FETCH_FAILED = "SENSEBOX_DB_MISC_FETCH_FAILED";
const SENSEBOX_DB_MISC_FETCH_SUCCEEDED = "SENSEBOX_DB_MISC_FETCH_SUCCEEDED";

const SENSEBOX_SENSOR_FETCH_REQUESTED = "SENSEBOX_SENSOR_FETCH_REQUESTED";
const SENSEBOX_SENSOR_FETCH_FAILED = "SENSEBOX_SENSOR_FETCH_FAILED";
const SENSEBOX_SENSOR_FETCH_SUCCEEDED = "SENSEBOX_SENSOR_FETCH_SUCCEEDED";

const GEOCODING_FETCH_REQUESTED = "GEOCODING_FETCH_REQUESTED";
const GEOCODING_FETCH_FAILED = "GEOCODING_FETCH_FAILED";
const GEOCODING_FETCH_SUCCEEDED = "GEOCODING_FETCH_SUCCEEDED";

const SUN_API_FETCH_REQUESTED = "SUN_API_FETCH_REQUESTED";
const SUN_API_FETCH_FAILED = "SUN_API_FETCH_FAILED";
const SUN_API_FETCH_SUCCEEDED = "SUN_API_FETCH_SUCCEEDED";

export {
  WEATHER_DATA_FETCH_REQUESTED,
  WEATHER_DATA_FETCH_FAILED,
  WEATHER_DATA_FETCH_SUCCEEDED,

  SENSEBOXES_FETCH_REQUESTED,
  SENSEBOXES_FETCH_FAILED,
  SENSEBOXES_FETCH_SUCCEEDED,

  SENSEBOX_INFO_FETCH_REQUESTED,
  SENSEBOX_INFO_FETCH_FAILED,
  SENSEBOX_INFO_FETCH_SUCCEEDED,

  SENSEBOX_DB_MISC_FETCH_REQUESTED,
  SENSEBOX_DB_MISC_FETCH_FAILED,
  SENSEBOX_DB_MISC_FETCH_SUCCEEDED,

  SENSEBOX_SENSOR_FETCH_REQUESTED,
  SENSEBOX_SENSOR_FETCH_FAILED,
  SENSEBOX_SENSOR_FETCH_SUCCEEDED,

  GEOCODING_FETCH_REQUESTED,
  GEOCODING_FETCH_FAILED,
  GEOCODING_FETCH_SUCCEEDED,

  SUN_API_FETCH_REQUESTED,
  SUN_API_FETCH_FAILED,
  SUN_API_FETCH_SUCCEEDED,
};
