{
  "name": "sensebox-data-dashboard",
  "private": true,
  "version": "0.4.2",
  "description": "sensebox-data-dashboard",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "authors": [],
  "scripts": {
    "start": "parcel src/index.html -p 3000",
    "build": "parcel build src/index.html --public-url ./",
    "predeploy": "npm run build",
    "deploy": "gh-pages -d dist"
  },
  "dependencies": {
    "@babel/runtime": "^7.17.9",
    "@mantine/core": "^5.2.3",
    "@mantine/hooks": "^5.2.3",
    "@mantine/notifications": "^5.2.3",
    "@mapbox/timespace": "^2.0.4",
    "@reduxjs/toolkit": "^1.8.3",
    "cross-fetch": "^3.1.5",
    "deck.gl": "^8.8.9",
    "identicon.js": "^2.3.3",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.38",
    "posthog-js": "^1.51.4",
    "prop-types": "^15.6.1",
    "react": "^17.0.0",
    "react-dom": "^17.0.0",
    "react-hot-loader": "^4.13.0",
    "react-map-gl": "^7.0.19",
    "react-redux": "^8.0.2",
    "react-router": "^6.2.1",
    "react-router-dom": "^6.2.1",
    "redux": "^4.2.0",
    "redux-actions": "^2.6.5",
    "redux-saga": "^1.1.3",
    "tabler-icons-react": "^1.52.0"
  },
  "devDependencies": {
    "@babel/eslint-parser": "^7.18.2",
    "@babel/plugin-proposal-class-properties": "^7.18.6",
    "@babel/plugin-proposal-object-rest-spread": "^7.18.6",
    "@babel/preset-env": "^7.18.10",
    "@babel/preset-react": "^7.18.6",
    "@parcel/babel-preset-env": "^2.7.0",
    "@parcel/config-default": "^2.6.2",
    "@parcel/css": "^1.14.0",
    "@parcel/packager-raw-url": "^2.6.2",
    "@parcel/transformer-css": "^2.7.0",
    "@parcel/transformer-js": "^2.6.2",
    "@parcel/transformer-react-refresh-wrap": "^2.6.2",
    "@parcel/transformer-sass": "^2.6.2",
    "@parcel/transformer-webmanifest": "^2.6.2",
    "@typescript-eslint/eslint-plugin": "^5.56.0",
    "@typescript-eslint/parser": "^5.56.0",
    "buffer": "^6.0.3",
    "eslint": "^8.19.0",
    "eslint-plugin-react": "^7.30.1",
    "eslint-plugin-react-hooks": "^4.6.0",
    "gh-pages": "^4.0.0",
    "parcel": "^2.6.2",
    "sass": "^1.53.0",
    "typescript": "^4.6.2"
  }
}
